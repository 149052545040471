<template>
    <div>
      <patent-annuity-will-pay-list></patent-annuity-will-pay-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  